<template>
  <div class="verify">
    <div v-if="loading" class="loading"></div>
    <div v-else-if="!registered">
      <div v-if="valid">
        <h2>登録</h2>
        <p>
        まだ登録は終わっていません。
        以下の必要事項を入力してください。
        </p>
        <label>
      お名前<br/>
        <input v-model="name" @input="input" />
        </label><br/>
        <label>
      <strong>10文字以上</strong>のパスワード<br/>
        <input type="password" v-model="password" @input="input" />
        </label><br/>
        <label>
      パスワード再入力<br/>
        <input type="password" v-model="password2" @input="input" />
        </label><br/>
        <button v-on:click="confirm" :disabled="disableNext">登録</button>
      </div>
      <div v-else>
        <p>認証できませんでした。</p>
      </div>
    </div>
    <div v-else>
      登録が完了しました。
      <router-link to="/login">こちらからログインしてください。</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'Verify',
	data() {
		return {
      name: '',
      password: '',
      password2: '',
      disableNext: true,
      valid: false,
      registered: false,
      loading: true
		}
	},
  mounted() {
    axios.get(config.API+'register-verify.php', {
      params: {
        id: this.$route.query.id,
        v: this.$route.query.v
      }
    }).then(res => {
      this.valid = (res.data.status == 'success');
      this.loading = false;
    });
  },
	methods: {
    input() {
      this.disableNext = this.name == '' || this.password.length < 10 || this.password2.length < 10 || this.password != this.password2;
    },
    confirm() {
      this.loading = true;
      axios.post(config.API+'register-verify.php', {
        id: this.$route.query.id,
        v: this.$route.query.v,
        name: this.name,
        password: this.password,
        password2: this.password2,
      }).then(res => {
        if (res.data.status == 'success') {
          this.registered = true;
        }
        else {
          alert(res.data.message);
        }
        this.loading = false;
      });
    }
	}
}
</script>

<style scoped>
.verify {
	margin: 4pt;
  text-align: center;
}
.verify input,
.verify button {
	margin: 4pt 0;
}
</style>
